<template>
    <main class="p-approach">
        <section class="intro" v-if="content">
            <h1 class="intro__title">{{ content.title }}</h1>
            <div class="intro__desc">{{ content.introDescription }}</div>
        </section>

        <section class="container columns olivier img-first" v-if="content">
            <img :src="content.olivierImageUrl" alt="" />
            <div>
                <h2>{{ content.olivierTitle }}</h2>
                <div>{{ content.olivierDescription }}</div>
            </div>
        </section>

        <section class="container columns no-padding-right img-last" v-if="content">
            <div>
                <h2>{{ content.officeTitle }}</h2>
                <div>{{ content.officeDescription }}</div>
            </div>
            <img :src="content.officeImageUrl" alt="" />
        </section>

        <section class="container columns production img-first" v-if="content">
            <img :src="content.productionImageUrl" alt="" />
            <div>
                <h2>{{ content.productionTitle }}</h2>
                <div class="description">{{ content.productionDescription }}</div>
            </div>
        </section>

        <section class="container columns expedition img-last" v-if="content">
            <div>
                <h2>{{ content.expeditionTitle }}</h2>
                <div>{{ content.expeditionDescription }}</div>
            </div>
            <img :src="content.expeditionImageUrl" alt="" />
        </section>

        <section class="container columns no-padding-left img-first" v-if="content">
            <img :src="content.workshopImageUrl" alt="" />
            <div>
                <h2>{{ content.workshopTitle }}</h2>
                <div>{{ content.workshopDescription }}</div>
                <Cta :type="'outline'" :ctaText="$t('approach.ctaWorkshop')" :ctaUrl="$t('nav.workshop.uri')" />
            </div>
        </section>

        <Cta :type="'big'" :ctaText="$t('global.ctaContactUs')" :ctaUrl="$t('nav.contact.uri')" />
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Cta from '@/components/Cta';

export default {
    name: 'Approach',

    components: {
        Cta
    },

    data() {
        return {
            content: null,
        }
    },

    created() {
        this.$craft.getContentBySlug(this.$t('nav.approach.slug'))
            .then(res => {
                this.content = res.data;
                if (res.data.seo) {
                    this.$store.commit('SET_SEO', res.data.seo);
                }
            })
            .then(() => {
                this.initAnimations();
            })
            .then(() => {
                this.$store.commit('SET_OTHER_LOCALES_URLS', this.content.otherLocalesUrls);
                this.$store.commit('SET_DATA_READY', true);
            });
    },

    updated() {
        ScrollTrigger.refresh(true);
    },

    methods: {
        initAnimations() {
            gsap.utils.toArray('.container.img-first img').forEach((image) => {
                gsap.from(image, {
                    opacity: 0,
                    x: 200,
                    duration: 1,
                    ease: 'Power1.inOut',
                    scrollTrigger: {
                        trigger: image,
                        start: 'top 50%',
                        end: '+=100%',
                    },
                });
            });

            gsap.utils.toArray('.container.img-last img').forEach((image) => {
                gsap.from(image, {
                    opacity: 0,
                    x: -200,
                    duration: 1,
                    ease: 'Power1.inOut',
                    scrollTrigger: {
                        trigger: image,
                        start: 'top 50%',
                        end: '+=100%',
                    },
                });
            });

            ScrollTrigger.refresh(true);
        }
    }
}
</script>
